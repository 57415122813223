import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="About RWK Attorneys" defer={false} />


		<div className="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><Link to="/">Home</Link></li>
					<li class="breadcrumb-item active" aria-current="page">About</li>
				</ol>
			</nav>

			<div className="row">
				<div className="col">
					<p>The lawyers at Russo, White &amp; Keller P.C. have over 75 years of combined legal experience serving residents across the State of Alabama, particularly in Jefferson, St. Clair, Blount and Shelby counties. We take great interest in taking care of all our clients and that is why we put forth the most aggressive representation for each of them. When you hire our firm, you are not just hiring one lawyer, you’re hiring all of the lawyers in our firm. Effective attorneys don’t just know the law; they understand it. For that reason, we work zealously as a team to obtain the best possible results for each client.  So, regardless of your particular legal need, Russo, White &amp; Keller, P.C. can provide you with the effective legal assistance to get you moving forward in the right direction.</p>

					<p>We would love to meet you and discuss your legal needs. Call us today to schedule your initial consultation.  This initial consultation is FREE.  So call us today and let the lawyers of Russo, White &amp; Keller, P.C. start working for you immediately!</p>
				</div>

				<div className="col-5">
					<div className="embed-responsive embed-responsive-4by3">
						<iframe title="map of location" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=315%20Gadsden%20Hwy%2C%20Suite%20D%20Birmingham%2C%20AL%2035235+(RWK%20Attorneys%2C%20P.C.)&amp;ie=UTF8&amp;t=h&amp;z=17&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no"></iframe>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)